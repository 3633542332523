/**
 * @generated SignedSource<<8432c2ff0bf1ecac6b6a16fae0b5eae2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
export type RefreshSessionOrChallengeInput = {
  id?: RefreshSessionOrChallengeInputId | null;
};
export type RefreshSessionOrChallengeInputId = {
  companyId?: string | null;
  externalCompanyId?: number | null;
};
export type commitRefreshSessionOrChallengeMutation$variables = {
  input: RefreshSessionOrChallengeInput;
};
export type commitRefreshSessionOrChallengeMutation$data = {
  readonly refreshSessionOrChallenge: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
  } | {
    readonly __typename: "RefreshSessionOrChallengeSuccess";
    readonly response: {
      readonly __typename: "ChallengeResponse";
      readonly __typename: "ChallengeResponse";
      readonly company: {
        readonly id: string;
        readonly internalId: number;
      };
      readonly nonce: string;
    } | {
      readonly __typename: "RefreshSessionResponse";
      readonly __typename: "RefreshSessionResponse";
      readonly company: {
        readonly id: string;
        readonly internalId: number;
      };
      readonly token: string;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type commitRefreshSessionOrChallengeMutation = {
  response: commitRefreshSessionOrChallengeMutation$data;
  variables: commitRefreshSessionOrChallengeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nonce",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "company",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "company",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "DefaultErrorFailure",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "commitRefreshSessionOrChallengeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "refreshSessionOrChallenge",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "response",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "RequiredField",
                        "field": (v6/*: any*/),
                        "action": "THROW",
                        "path": "refreshSessionOrChallenge.response.company"
                      }
                    ],
                    "type": "ChallengeResponse",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v7/*: any*/),
                        "action": "THROW",
                        "path": "refreshSessionOrChallenge.response.company"
                      },
                      (v8/*: any*/)
                    ],
                    "type": "RefreshSessionResponse",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "RefreshSessionOrChallengeSuccess",
            "abstractKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "commitRefreshSessionOrChallengeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "refreshSessionOrChallenge",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "response",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/)
                    ],
                    "type": "ChallengeResponse",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "type": "RefreshSessionResponse",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "RefreshSessionOrChallengeSuccess",
            "abstractKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5eb84a458d2836014eb3342d9b45c71c",
    "id": null,
    "metadata": {},
    "name": "commitRefreshSessionOrChallengeMutation",
    "operationKind": "mutation",
    "text": "mutation commitRefreshSessionOrChallengeMutation(\n  $input: RefreshSessionOrChallengeInput!\n) {\n  refreshSessionOrChallenge(input: $input) {\n    __typename\n    ... on RefreshSessionOrChallengeSuccess {\n      response {\n        __typename\n        ... on ChallengeResponse {\n          __typename\n          nonce\n          company {\n            internalId\n            id\n          }\n        }\n        ... on RefreshSessionResponse {\n          __typename\n          company {\n            id\n            internalId\n          }\n          token\n        }\n      }\n    }\n    ... on DefaultErrorFailure {\n      status\n      message\n      title\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "08111f5e6ea696158717741675a1b38b";

export default node;
