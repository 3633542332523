/**
 * @generated SignedSource<<19c84e2fd3bedee17b9f1ed3b340c395>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PreferredMfaMethod = "PREFERRED_MFA_METHOD_EMAIL" | "PREFERRED_MFA_METHOD_SMS" | "%future added value";
export type LoginWithPasswordInput = {
  deviceId?: string | null;
  email: string;
  password: string;
};
export type UseLoginWithPasswordMutation$variables = {
  input: LoginWithPasswordInput;
};
export type UseLoginWithPasswordMutation$data = {
  readonly loginWithPassword: {
    readonly response: {
      readonly __typename: "LoginWithPasswordFailureResponse";
      readonly code: string;
      readonly message: string;
    } | {
      readonly __typename: "LoginWithPasswordMfaResponse";
      readonly mfaEmailHint: string | null;
      readonly mfaEnabled: boolean;
      readonly mfaPhoneNumberHint: string | null;
      readonly mfaToken: string;
      readonly preferredMfaMethod: PreferredMfaMethod;
    } | {
      readonly __typename: "LoginWithPasswordSuccessResponse";
      readonly jwtToken: string;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | null;
};
export type UseLoginWithPasswordMutation = {
  response: UseLoginWithPasswordMutation$data;
  variables: UseLoginWithPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LoginWithPasswordPayload",
    "kind": "LinkedField",
    "name": "loginWithPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "response",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jwtToken",
                "storageKey": null
              }
            ],
            "type": "LoginWithPasswordSuccessResponse",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "LoginWithPasswordFailureResponse",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mfaToken",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mfaEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mfaEmailHint",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mfaPhoneNumberHint",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "preferredMfaMethod",
                "storageKey": null
              }
            ],
            "type": "LoginWithPasswordMfaResponse",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UseLoginWithPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UseLoginWithPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6615c08fdcfe4ab4522afeba6727ba9c",
    "id": null,
    "metadata": {},
    "name": "UseLoginWithPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation UseLoginWithPasswordMutation(\n  $input: LoginWithPasswordInput!\n) {\n  loginWithPassword(input: $input) {\n    response {\n      __typename\n      ... on LoginWithPasswordSuccessResponse {\n        jwtToken\n      }\n      ... on LoginWithPasswordFailureResponse {\n        code\n        message\n      }\n      ... on LoginWithPasswordMfaResponse {\n        mfaToken\n        mfaEnabled\n        mfaEmailHint\n        mfaPhoneNumberHint\n        preferredMfaMethod\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "40eb7b37fe828c89afb22b052fbb4926";

export default node;
