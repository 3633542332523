import _UseLoginWithPasswordMutation from "./__generated__/UseLoginWithPasswordMutation.graphql";
import { useCallback } from 'react';
import { useMutation } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { AuthSession } from '@attentive/acore-utils';
import { AuthFlowStrategy, graphql } from '@attentive/data';
import { buildAuthError, parseAuthErrors } from './utils';
import LoginWithPasswordMutation from './__generated__/UseLoginWithPasswordMutation.graphql';
_UseLoginWithPasswordMutation;
export const useLoginWithPassword = mfaPath => {
  const [commitMutation] = useMutation(LoginWithPasswordMutation);
  const navigate = useNavigate();
  return useCallback((email, password, redirectPath) => {
    const deviceId = AuthSession.retrieveDeviceIdentifier();
    return new Promise((resolve, reject) => {
      commitMutation({
        variables: {
          input: {
            email,
            password,
            deviceId
          }
        },
        onCompleted: (response, errors) => {
          var _response$loginWithPa, _response$loginWithPa2, _response$loginWithPa3, _response$loginWithPa4, _response$loginWithPa5, _response$loginWithPa6;

          if (((_response$loginWithPa = response.loginWithPassword) === null || _response$loginWithPa === void 0 ? void 0 : (_response$loginWithPa2 = _response$loginWithPa.response) === null || _response$loginWithPa2 === void 0 ? void 0 : _response$loginWithPa2.__typename) === 'LoginWithPasswordSuccessResponse') {
            const token = response.loginWithPassword.response.jwtToken;
            AuthSession.persistStrategy(AuthFlowStrategy.Internal);
            AuthSession.persistToken(AuthFlowStrategy.Internal, token);
            window.location.assign(redirectPath);
            return resolve();
          }

          if (((_response$loginWithPa3 = response.loginWithPassword) === null || _response$loginWithPa3 === void 0 ? void 0 : (_response$loginWithPa4 = _response$loginWithPa3.response) === null || _response$loginWithPa4 === void 0 ? void 0 : _response$loginWithPa4.__typename) === 'LoginWithPasswordFailureResponse') {
            const {
              code,
              message
            } = response.loginWithPassword.response;
            const error = buildAuthError(code, message);
            return reject(error);
          }

          if (((_response$loginWithPa5 = response.loginWithPassword) === null || _response$loginWithPa5 === void 0 ? void 0 : (_response$loginWithPa6 = _response$loginWithPa5.response) === null || _response$loginWithPa6 === void 0 ? void 0 : _response$loginWithPa6.__typename) === 'LoginWithPasswordMfaResponse') {
            const {
              mfaEnabled,
              mfaToken,
              mfaEmailHint,
              mfaPhoneNumberHint,
              preferredMfaMethod
            } = response.loginWithPassword.response;
            const searchParams = new URLSearchParams({
              mfaToken,
              method: preferredMfaMethod,
              initMfa: (!mfaEnabled).toString(),
              redir: redirectPath
            });

            if (mfaEmailHint) {
              searchParams.set('email', mfaEmailHint);
            }

            if (mfaPhoneNumberHint) {
              searchParams.set('sms', mfaPhoneNumberHint);
            }

            navigate("".concat(mfaPath, "?").concat(searchParams));
            return resolve();
          }

          return reject(parseAuthErrors(errors));
        },
        onError: error => {
          reject(error);
        }
      });
    });
  }, [commitMutation, navigate, mfaPath]);
};